<template>
  <div :style="{width,height}">
    <vueCropper
      ref="cropper"
      :img="option.img"
      :outputSize="option.size"
      :outputType="option.outputType"
      :info="true"
      :full="option.full"
      :canMove="option.canMove"
      :canMoveBox="option.canMoveBox"
      :original="option.original"
      :autoCrop="option.autoCrop"
      :fixed="option.fixed"
      :fixedNumber="option.fixedNumber"
      :centerBox="option.centerBox"
      :infoTrue="option.infoTrue"
      :fixedBox="option.fixedBox"
    ></vueCropper>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true, 
    },
    width: {
      type: String,
      default: '580px'
    },
    height: {
      type: String,
      default: '428px'
    },
    option: {
      // 裁剪组件的基础配置option
      type: Object,
      default: () => {
        return {
          img: "", // 裁剪图片的地址
          info: true, // 裁剪框的大小信息
          outputSize: 1, // 裁剪生成图片的质量
          outputType: "png", // 裁剪生成图片的格式
          canScale: true, // 图片是否允许滚轮缩放
          autoCrop: true, // 是否默认生成截图框
          autoCropWidth: 600, // 默认生成截图框宽度
          autoCropHeight: 500, // 默认生成截图框高度
          fixedBox: false, // 固定截图框大小 不允许改变
          fixed: true, // 是否开启截图框宽高固定比例
          fixedNumber: [1920,1080], // 截图框的宽高比例
          full: true, // 是否输出原图比例的截图
          canMoveBox: true, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          centerBox: true, // 截图框是否被限制在图片里面
          infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        };
      },
    },
  },
  data() {
    return {
      uploadData: {},
    };
  },
  methods: {
    setAvatarBase64(src, callback) {
      let _this = this;
      let image = new Image();
      // 处理缓存
      image.src = src + "?v=" + Math.random();
      // 支持跨域图片
      image.crossOrigin = "*";
      image.onload = function () {
        let base64 = _this.transBase64FromImage(image);
        callback && callback(base64);
      };
    },
    // 将网络图片转换成base64格式
    transBase64FromImage(image) {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      // 可选其他值 image/jpeg
      return canvas.toDataURL("image/png");
    },
    //确定裁剪
    async save() {
      this.$Message.loading({
        content: "正在裁剪，请稍等...",
        duration: 0,
      });
      if (!this.uploadData.host) {
        await this.$get({
         url: "/datamsg/api/common/file/getWebPolicy?bucketName=fnc"
        }).then((res) => {
          if (res.code == 200) {
            let name = this.imageUrl.substring(
              this.imageUrl.lastIndexOf("/") + 1,
              this.imageUrl.lastIndexOf("?") == -1 ? this.imageUrl.length +1 : this.imageUrl.lastIndexOf("?")
            );
            this.uploadData = {
              name: name,
              host: res.data.host,
              key: res.data.key + name,
              policy: res.data.encodedPolicy,
              OSSAccessKeyId: res.data.accessId,
              success_action_status: "200",
              signature: res.data.postSignature,
            };
          }
        });
      }
      this.$refs.cropper.getCropBlob((data) => {
        let formData = new FormData();
        formData.append("name", this.uploadData.name);
        formData.append("host", this.uploadData.host);
        formData.append("key", this.uploadData.key);
        formData.append("policy", this.uploadData.policy);
        formData.append("OSSAccessKeyId", this.uploadData.OSSAccessKeyId);
        formData.append(
          "success_action_status",
          this.uploadData.success_action_status
        );
        formData.append("signature", this.uploadData.signature);
        formData.append("file", data);
        this.$post({url:this.uploadData.host, data:formData, header:{
          "Content-Type": "multipart/form-data",
        }}).then((res) => {
          this.$Message.destroy();
          this.$Message.success({
            background: true,
            content: "裁剪完成",
          });
          this.$emit("getImgUrl", this.uploadData.host + this.uploadData.key);
        });
      });
    },
  },
  created() {
    let _this = this;
    this.setAvatarBase64(this.imageUrl, (base64) => {
      _this.option.img = base64;
    });
  }
};
</script>
    
<style scoped lang='less'>
</style>